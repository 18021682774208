import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import { AutoCompleteSelect, SimpleSelect } from 'components/CustomSelectFields';
import { DocumentOptions } from './constants';
import { useServices } from 'contexts';
import { getCompanies, getTags } from 'services/tagService';
import { getDomains } from 'services';
import TextField, { TextArea } from 'components/CustomFields';
import CustomButton from 'components/CustomButton';
import useStore from './store';


const useStyles = makeStyles((theme) => ({
  jobDetailsBotContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    width: '484px',
  },
  jobDetailsBotContentsCompany: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  jobDetailsSelectFields: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  jobDetailsSelectFieldsResume: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(5),
    width: '100%',
  },
  noJobRole: {
    textAlign: 'center',
    width: '497px',
    cursor:'pointer'
  },
}));

const SelectJobDetails = ({
  stepNumber, next, openEnterCompanyField, setOpenEnterCompanyField, clientMock,setIsDataChanged
}) => {
  const classes = useStyles();
  const service = useServices();

  const [
          selectedDocument,jobDescription,selectCompany,companyName,selectJobRole,jobRole,domain,
          jobRoles,setJobDescription,setSelectCompany,setCompanyName,setSelectJobRole,
          setJobRole,setDomain,setJobRoles
        ] = useStore(store => [
                                store.selectedDocument,store.jobDescription,store.selectCompany,
                                store.companyName,store.selectJobRole,store.jobRole,store.domain,
                                store.jobRoles,store.setJobDescription,store.setSelectCompany,
                                store.setCompanyName,store.setSelectJobRole,
                                store.setJobRole,store.setDomain,store.setJobRoles
                              ])

  const [openJobDescription, setOpenJobDescription] = useState(false);
  const [openJobRoleField, setOpenJobRoleField] = useState(false);
  const [companies, setCompanies] = useState();
  const [jobRolesForResume , setJobRolesForResume] = useState([])
  const [domains, setDomains] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        const fetchedCompanies = await service.callService(getCompanies);      
        const sortedCompanies = Object.values(fetchedCompanies)
        .sort((a, b) => a.name.localeCompare(b.name)); 
        setCompanies(sortedCompanies);

        const tagFilter = {
          category: "jobRole",
          include : ['domain']
        };
        const fetchedJobRoles = await service.callService(getTags, tagFilter)
        const filteredJobRoles = fetchedJobRoles.tags
        .filter(tag => tag.domain) 
        .sort((a, b) => a.code.localeCompare(b.code))
        .reduce((uniqueJobRoles, currentTag) => {
          if (!uniqueJobRoles.some(existingTag => existingTag.code === currentTag.code)) {
            return [...uniqueJobRoles, { ...currentTag, name: currentTag.name }];
          }
          return uniqueJobRoles;
        }, []);
      
        setJobRolesForResume(filteredJobRoles);
            
        const domains = await service.callService(getDomains);
        setDomains(domains.interviewDomains.sort((a, b) => a.name.localeCompare(b.name)));
        
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInterviewDetails();
  }, []);

  useEffect(() => {
    if (jobDescription) {
      setOpenJobDescription(true);
    }
  }, [jobDescription]);

  const handleNextStep = () => {
    if(selectedDocument === DocumentOptions.JD_RESUME._id || selectedDocument === DocumentOptions.JD._id){
      if(openEnterCompanyField){
        if(companyName && jobRole && domain && jobDescription){
          next(stepNumber + 1);
          setIsDataChanged(false)
          setError(false)
      }else{
        setError(true)
      }
      }else{
        if(selectCompany && selectJobRole && domain && jobDescription){
          next(stepNumber + 1);
          setIsDataChanged(false)
          setError(false)
        }else{
          setError(true)
        }
      }
    } else {
      if(openJobRoleField){
        if(jobRole && domain){
          next(stepNumber + 1);
          setIsDataChanged(false)
          setError(false)
        }else {
          setError(true)
        }
      }else {
        if(selectJobRole && domain){
          next(stepNumber + 1);
          setError(false)
          setIsDataChanged(false)
        }else{
          setError(true)
        }
      }
    }
  };

  const handleOpenJD = () => {
    if(selectCompany && selectJobRole){
      setOpenJobDescription(true);
      setError(false)
    }else{
      setError(true)
    }
  };

  const handleNoCompanyField = () => {
    setOpenEnterCompanyField(true);
    setJobDescription('');
    setSelectCompany('');
    setSelectJobRole('');
    setDomain('')
  };

  const handleJobRoleTextField = () => {
    setOpenJobRoleField(true);
    setSelectJobRole('')
  };

  const handleCompanyName = (e) => {
    setCompanyName(e.target.value)
  }

  const handleJobRole = (e) => {
    setJobRole(e.target.value)
  }

  const handleJobDescriptionChange = (e) => {
    if (!clientMock) {
      setJobDescription(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (clientMock || !openEnterCompanyField) {
      e.preventDefault();
    }
  };

  const handleSelectCompany = (data) => {
    if(selectJobRole){
      setOpenJobDescription(false)
      setSelectJobRole('')
      setJobDescription('')
    }
      setSelectCompany(data);
      setJobRoles(data?.jobRoles.sort((a, b) => a.name.localeCompare(b.name)));
};

  const handleSelectJobRole = (data) => {
      setSelectJobRole(data)
      setDomain(data?.domain)
      setJobDescription(data?.jobDescriptions?.content)
  };

  const isButtonDisabled = () => {
    return !companyName || !jobRole || !domain || !jobDescription;
  };

  return (
    <>
      {
        selectedDocument === DocumentOptions.RESUME._id 
          ?
            (
              <>
                <Box className={classes.jobDetailsBotContents}>
                  <Box className={classes.jobDetailsSelectFieldsResume}>
                    {openJobRoleField 
                      ? (
                          <>
                            <TextField
                              label="Enter Job role"
                              style={{ width: '232px' }}
                              placeholder='Job role'
                              value={jobRole}
                              onChange={handleJobRole}
                              error={error}
                            />
                            <AutoCompleteSelect
                              options={domains || []}
                              value={domain}
                              label='Select Domain'
                              style={{ width: '232px' }}
                              placeholder='Select Domain'
                              onSelect={(data)=>setDomain(data)}
                              error={error}
                              menuStyle={{maxHeight: '200px'}}
                              width='232px'
                            />
                          </>
                        )  
                      :
                        (
                          <AutoCompleteSelect
                            options={jobRolesForResume || []}
                            value={selectJobRole}
                            label='Select Job Role'
                            style={{ width: '232px' }}
                            onSelect={(data)=>{
                              setSelectJobRole(data)
                              setDomain(data?.domain)
                            }}
                            error={error}
                            menuStyle={{maxHeight: '200px'}}
                            width='232px'
                          />
                        )
                    }               
                  </Box>
                  <CustomButton
                    variant='contained'
                    style={openJobRoleField ? { width: '100%' } : { width: '232px' }}
                    size='medium'
                    onClick={handleNextStep}
                    disabled={openJobRoleField ? !jobRole || !domain : !selectJobRole}
                  >
                    Next
                  </CustomButton>
                  {!openJobRoleField && (
                    <Typography
                      className={classes.noJobRole}
                      variant='body01-link'
                      color='primary.clr-300'
                      onClick={handleJobRoleTextField}
                    >
                      Didn’t find the job role you are looking for? Click here
                    </Typography>
                  )}
                </Box>
              </>
            ) 
          :
            (
              <>
                {openEnterCompanyField 
                  ? (
                      <>
                        <Box className={classes.jobDetailsBotContentsCompany}>
                          <Box className={classes.jobDetailsSelectFields}>
                            <TextField
                              label="Enter company name"
                              style={{ width: '232px' }}
                              placeholder='Company name'
                              value={companyName}
                              disabled={clientMock === true}
                              onChange={handleCompanyName}
                              error={error}
                            />
                            <TextField
                              label="Enter Job role"
                              style={{ width: '232px' }}
                              placeholder='Job role'
                              value={jobRole}
                              disabled={clientMock === true}
                              onChange={handleJobRole}
                              error={error}
                            />
                            {clientMock
                              ? 
                                (
                                  <SimpleSelect
                                    options={domains || []}
                                    label="Select Domain"
                                    style={{ width: '232px' }}
                                    placeholder='Select Domain'
                                    value={{ _id : domain}}
                                    disabled={clientMock === true}
                                    error={error}
                                  />
                                )
                              :
                                (
                                  <AutoCompleteSelect
                                    options={domains || []}
                                    value={domain}
                                    label='Select Domain'
                                    style={{ width: '232px' }}
                                    placeholder='Select Domain'
                                    onSelect={(data)=>setDomain(data)}
                                    menuStyle={{maxHeight: '200px'}}
                                    width='232px'
                                  />
                                )
                            }
                          </Box>
                          <TextArea
                            label={clientMock ? 'Job description (view only)' : 'Job description'}
                            rows={4}
                            placeholder='Paste company job description here...'
                            sx={{
                              width: '744px',
                            }}
                            value={jobDescription}
                            onChange={handleJobDescriptionChange}
                            onKeyDown={handleKeyDown}
                            error={error}
                          />
                          <CustomButton
                            variant='contained'
                            style={{ width: '100%' }}
                            size='medium'
                            onClick={handleNextStep}
                            disabled={isButtonDisabled()}
                          >
                            Next
                          </CustomButton>
                        </Box>
                      </>
                    ) 
                  : 
                    (
                      <Box className={classes.jobDetailsBotContents}>
                        <Box className={classes.jobDetailsSelectFields}>
                          <AutoCompleteSelect
                            options={companies || []}
                            value={selectCompany|| []}
                            label='Select Company'
                            style={{ width: '232px' }}
                            onSelect={handleSelectCompany}
                            error={error}
                            menuStyle={{maxHeight: '200px'}}
                            width='232px'
                          />
                          <AutoCompleteSelect
                            options={jobRoles || []}
                            value={selectJobRole|| []}
                            label='Select Job Role'
                            style={{ width: '232px' }}
                            onSelect={handleSelectJobRole}
                            error={error}
                            menuStyle={{maxHeight: '200px'}}
                            disabled={!selectCompany}
                            width='232px'
                          />
                        </Box>
                        {openJobDescription 
                          ? 
                            (
                              <>
                                <TextArea
                                  label='Job description (view only)'
                                  rows={4}
                                  placeholder='Paste company job description here...'
                                  onChange={handleJobDescriptionChange}
                                  onKeyDown={handleKeyDown}
                                  value={jobDescription}
                                  sx={{
                                    width: '484px',
                                  }}
                                  error={error}
                                />
                                <CustomButton
                                  variant='contained'
                                  style={{ width: '100%' }}
                                  size='medium'
                                  onClick={handleNextStep}
                                  disabled={!selectCompany || !selectJobRole || !jobDescription}
                                >
                                  Next
                                </CustomButton>
                              </>
                            ) 
                          : 
                            (
                              <CustomButton
                                variant='contained'
                                style={{ width: '100%' }}
                                size='medium'
                                onClick={handleOpenJD}
                                disabled={!selectCompany || !selectJobRole}
                              >
                                Next
                              </CustomButton>
                            )
                        }
                        <Typography
                          className={classes.noJobRole}
                          variant='body01-link'
                          color='primary.clr-300'
                          onClick={handleNoCompanyField}
                        >
                          Didn’t find the company or job role you are looking for? Click here
                        </Typography>
                      </Box>
                    )
                  }
              </>
            )
      }
    </>
  );
};

export default SelectJobDetails;