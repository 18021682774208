import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';

import StartAssessmentDialog from "dialogs/StartAssessmentDialog";
import StatusChip from "./Chip";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "contexts";
import { getTwoDigitNo } from "utils";
import { templateTypes } from "./PerformanceReview/constants";
import { useNavigateWithClient } from "hooks";
import TitleTooltip from "./TitleTooltip";

const notesIcon = "https://languify-assets.s3.ap-south-1.amazonaws.com/images/notesIcon.svg";
const refreshIcon = "https://languify-assets.s3.ap-south-1.amazonaws.com/images/refreshIcon.svg";
const timerIcon = "https://languify-assets.s3.ap-south-1.amazonaws.com/images/timerIcon.svg";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    position: "relative",
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: 'space-between',
    width: "405px",
    height: "210px",
    border : `3px solid ${theme.palette.neutral['clr-200']}`,
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    flex: "0 0 auto",
    transition: "box-shadow 10ms linear",
    '&:hover': {
      border : `3px solid ${theme.palette.primary['clr-100']}`,
      transition: 'box-shadow 0.1s ease-in-out',
    },
    position: 'relative',
    overflow: 'hidden',
  },
  disabledCard: {
    position: "relative",
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: 'space-between',
    width: "405px",
    height: "210px",
    border : `3px solid ${theme.palette.neutral['clr-300']}`,
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    flex: "0 0 auto",
    transition: "box-shadow 10ms linear",
    backgroundColor: theme.palette.neutral.disabled,
    position: 'relative',
    overflow: 'hidden',
    cursor: 'default',
    '&>*': {
      color: `${theme.palette.neutral['clr-600']} !important`
    }
  },
  cardContent: {
    display: "flex", flexDirection: "column", justifyContent: "flex-end", zIndex: '2'
  },
  title: {
    ...theme.typography['h4-semiBold'],
    textTransform: 'capitalize',
    marginTop: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '365px'
  },
  subtitle: {
    ...theme.typography['body02-bold'],
    textTransform: "capitalize",
    color: theme.palette.neutral['clr-600']
  },
  content: {
    ...theme.typography['body01-semiBold'],
    color: theme.palette.neutral['clr-700'],
    display: "flex",
    alignItems: "center"
  },
  templateDetails: {
    display: 'flex', justifyContent: 'space-between',
    gap: theme.spacing(2)
  },
  circularColorPads: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  interviewIcon:{
    position: 'absolute',
    top: '20px',
    right: '20px',
  }
}));

function formateAverageTime(time) {
  if (!time) return null;
  return time < 60 ? `${getTwoDigitNo(time)} Secs` : `${getTwoDigitNo(Math.floor(time / 60))} Mins`;
}

export function TemplateCardSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer}>
      <Box display="flex">
        <Skeleton variant="rectangular" width="20%" height='30px' style={{ borderRadius: '20px' }} />
      </Box>
      <Box className={classes.cardContent}>
        <Box>
          <Skeleton variant="rectangular" width="90%" height='40px' />
        </Box>
        <Box mt='10px'>
          <Skeleton variant="rectangular" width="100%" height='20px' />
        </Box>
      </Box>
    </Box>
  );
}

export function TemplateCard({
  template, onClick, defaultTemplate, disabled, bgColor = '',
  cardTile = 'https://languify-assets.s3.ap-south-1.amazonaws.com/images/circularPads.svg',
  personalizedIntreview,
  onExpire = () => { }, ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const navigateWithClient = useNavigateWithClient();

  const [expire, setExpire] = useState('Expires Soon');
  const [expireSoon, setExpireSoon] = useState(false);
  const [expiresOn , setExpiresOn] = useState('')

  // Determine which template to use based on personalizedIntreview
  const currentTemplate = personalizedIntreview ? template.draft : template;

  const handleCardClick = useCallback((e) => {
    if (personalizedIntreview) {
      if (template._id) {
        onClick(template);
      } else {
        navigateWithClient(`/personalized-interview/${currentTemplate._id}`)
      }
    } else {
      onClick(currentTemplate);
    }
  },
    [currentTemplate, personalizedIntreview]
  );

  const countDownTimer = (startTime, endTime) => {
    const timeDifference = endTime.diff(startTime, 'second');
    let remainingTime = timeDifference;
    let timeout = null;

    if (timeDifference > 0) {
      timeout = setInterval(() => {
        if (remainingTime <= 0) {
          clearInterval(timeout);
          setExpireSoon(false);
          onExpire(currentTemplate._id);
          console.log("Template expired!");
        } else {
          const hours = Math.floor(remainingTime / 3600);
          const minutes = Math.floor((remainingTime % 3600) / 60);
          const seconds = remainingTime % 60;

          setExpire(`Expires in ${String(hours).padStart(2, '0')}:${String(minutes)
            .padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
          remainingTime--;
        }
      }, 1000);
    } else {
      if (timeout) clearInterval(timeout);
      setExpireSoon(false);
      onExpire(currentTemplate._id);
      console.log("Template expired!");
    }
  }

  useEffect(() => {
    if (!currentTemplate) return; 
  
    let expiresOn = currentTemplate.expiresOn;

    if (currentTemplate.type === 'personalized') {
      expiresOn = currentTemplate.data?.INTERVIEW_DETAILS?.expiresOn;
    }

    if (expiresOn && dayjs(expiresOn).year() === 9999) {
      setExpire('Never Expires');
    } else if (expiresOn) {
      const formattedDate = dayjs(expiresOn).format("DD/MM/YYYY");
      setExpire(`Expires on: ${formattedDate}`);
    }
  }, [currentTemplate]);

  useEffect(() => {
    const { publishOn, expiresOn, thresholdDateForExpire } = currentTemplate;
  
    if (publishOn && expiresOn) {

      if (dayjs(expiresOn).year() !== 9999) {
        const startDate = dayjs(publishOn);
        const endDate = dayjs(expiresOn);

        const completedDate = dayjs();

        if (completedDate.isSame(endDate, "day")) {
          if (completedDate.isAfter(thresholdDateForExpire)) {
            countDownTimer(completedDate, endDate);
          }
        } else {
          if (completedDate.isAfter(thresholdDateForExpire)) {
            const remainingDays = endDate.diff(startDate, "day") - completedDate.diff(publishOn, "day");
            setExpire(
              (remainingDays === 1) ?
                `Expires in ${remainingDays} day` :
                `Expires in ${remainingDays} days`
            );
          }
        }
      }
    }
  }, [defaultTemplate, currentTemplate]);

  useEffect(() => {
    if (defaultTemplate) {
      setExpireSoon(defaultTemplate.expiresSoon);
    }
  }, [defaultTemplate]);

  return (
    <TitleTooltip
      placement='bottom'
      component={currentTemplate?.type === 'personalized' 
                  ? currentTemplate?.data?.INTERVIEW_DETAILS?.name 
                  : currentTemplate?.name || ""}
      disableHoverListener={currentTemplate?.type === 'personalized' 
                            ? currentTemplate?.data?.INTERVIEW_DETAILS?.name.length < 28 
                            : currentTemplate?.name.length < 28}
      positionStyle={{ top: '-160px !important', left : '60px !important' , zIndex:2 }}
      onClick={disabled ? undefined : handleCardClick}
    >
      <Box
        className={`${disabled ? classes.disabledCard : classes.cardContainer}`}
        onClick={disabled ? undefined : handleCardClick}
        sx={{ backgroundColor: bgColor, ...props }}
      >
        { personalizedIntreview 
          ? 
            (
              <Box className={classes.interviewIcon}>
                <img src={cardTile} alt="crc" />
              </Box>
            ) 
          : 
            (
              <Box className={classes.circularColorPads}>
                <img src={cardTile} alt="crc" />
              </Box>
            )
         }
        <Box display="flex" gap='8px' flexWrap='wrap'>
          {currentTemplate.type === templateTypes.ASSIGNMENT 
            ? 
              (
                <>
                  {currentTemplate.new && (
                    <>
                      <StatusChip
                        content={"New"}
                        color={theme.palette.tertiary['clr-700']}
                        bgColor={theme.palette.tertiary['clr-100']}
                        disabled={disabled}
                        sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                      />
                      <StatusChip
                        content={expire}
                        color={theme.palette.primary.main}
                        bgColor={theme.palette.primary['clr-50']}
                        disabled={disabled}
                        sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                      />
                    </>
                  )}
                  {!currentTemplate.new && (
                    <>
                      {expireSoon ? (
                        <StatusChip
                          content={expire}
                          color={theme.palette.danger['clr-700']}
                          bgColor={theme.palette.danger['clr-100']}
                          disabled={disabled}
                          sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                        />
                      ) :  <StatusChip
                            content={expire}
                            color={theme.palette.primary.main}
                            bgColor={theme.palette.primary['clr-50']}
                            disabled={disabled}
                            sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                          />}
                    </>
                  )}
                </>
              ) 
            : currentTemplate.type === templateTypes.PRACTICE 
            ? 
              (
                  <>
                    {currentTemplate.new && (
                      <>
                        <StatusChip
                          content={"New"}
                          color={theme.palette.tertiary['clr-700']}
                          bgColor={theme.palette.tertiary['clr-100']}
                          disabled={disabled}
                          sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                        />
                        <StatusChip
                          content={expire}
                          color={theme.palette.primary.main}
                          bgColor={theme.palette.primary['clr-50']}
                          disabled={disabled}
                          sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                        />
                        {currentTemplate.maxAllowedAttempts === -1 ? (
                          <StatusChip
                            content={'Unlimited Attempts'}
                            color={theme.palette.secondary['clr-900']}
                            disabled={disabled}
                            sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                          />
                        ) : (
                          <StatusChip
                            content={`Attempts Left: ${getTwoDigitNo(currentTemplate.maxAllowedAttempts - currentTemplate.attempts )}`}
                            color={theme.palette.secondary['clr-900']}
                            disabled={disabled}
                            sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                          />
                        )}
                      </>
                    )}
                    {!currentTemplate.new && (
                      <>
                        {expireSoon ? (
                          <StatusChip
                            content={expire}
                            color={theme.palette.danger['clr-700']}
                            bgColor={theme.palette.danger['clr-100']}
                            disabled={disabled}
                            sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                          />
                        ) : (
                        <>
                          <StatusChip
                            content={expire}
                            color={theme.palette.primary.main}
                            bgColor={theme.palette.primary['clr-50']}
                            disabled={disabled}
                            sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                          />
                             {currentTemplate.maxAllowedAttempts === -1 ? (
                            <StatusChip
                              content={'Unlimited Attempts'}
                              color={theme.palette.secondary['clr-900']}
                              disabled={disabled}
                              sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                            />
                          ) : (
                            <StatusChip
                              content={`Attempts Left: ${getTwoDigitNo(currentTemplate.maxAllowedAttempts - currentTemplate.attempts)}`}
                              color={theme.palette.secondary['clr-900']}
                              disabled={disabled}
                              sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                            />
                          )}
                        </>       
                        )}
                      </>
                    )}
                  </>
              ) 
            : currentTemplate.type === templateTypes.PERSONALIZED 
            ? 
            (
              <>
                {currentTemplate.new ? (
                  <>
                    <StatusChip
                      content={"New"}
                      color={theme.palette.tertiary['clr-700']}
                      bgColor={theme.palette.tertiary['clr-100']}
                      disabled={disabled}
                      sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                    />
                    <StatusChip
                      content={expire}
                      color={theme.palette.primary.main}
                      bgColor={theme.palette.primary['clr-50']}
                      disabled={disabled}
                      sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                    />
                  </>
                ) : (
                  <>
                    {expireSoon ? (
                      <StatusChip
                        content={expire}
                        color={theme.palette.danger['clr-700']}
                        bgColor={theme.palette.danger['clr-100']}
                        disabled={disabled}
                        sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                      />
                    ) : (
                      <StatusChip
                        content={expire}
                        color={theme.palette.primary.main}
                        bgColor={theme.palette.primary['clr-50']}
                        disabled={disabled}
                        sx={{ padding: theme.spacing(1, 2), borderRadius: '4px' }}
                      />
                    )}
                  </>
                )}
              </>
            ) 
            : null
          }
        </Box>
        <Box className={classes.cardContent}>
          <Box mb={theme.spacing(2)}>
            <Typography className={classes.subtitle}>
              {currentTemplate?.type === 'personalized' ? 'Mock Interview' : currentTemplate?.type || ""}
            </Typography>
            <Typography className={classes.title}>
              {currentTemplate?.type === 'personalized' ? currentTemplate?.data?.INTERVIEW_DETAILS?.name : currentTemplate?.name || ""}
            </Typography>
          </Box>
          <Box className={classes.templateDetails}>
            {personalizedIntreview ? (
              <></>
            ) : (
              <>
                <Typography className={classes.content}>
                  <img src={notesIcon} alt="notes" />&nbsp;
                  {
                    (currentTemplate?.type === 'follow_up') ? '05'
                      : getTwoDigitNo(currentTemplate?.metadata?.numberOfQuestions || 0)
                  } Questions
                </Typography>
                <Typography className={classes.content}>
                  <img src={timerIcon} alt="timer" />&nbsp;
                  {
                    (currentTemplate?.type === 'follow_up') ?
                      '5-10 mins' :
                      formateAverageTime(currentTemplate?.metadata?.testTime) || "NA"
                  }
                </Typography>
              </>
            )}
            <Typography className={classes.content}>
              <img src={refreshIcon} alt="refresh" />&nbsp;
              {template._id && personalizedIntreview
                ? template.totalAttemptsCount === 0 ? 'No Attempts' : `${getTwoDigitNo(template.totalAttemptsCount)}x Attempted`
                : (currentTemplate?.attempts === undefined || currentTemplate?.attempts === 0)
                  ? 'No Attempts'
                  : `${getTwoDigitNo(currentTemplate.attempts)}x Attempted`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </TitleTooltip>
  );
}

export default function TemplateList({
  defaultTemplates = [], templates = [], onTemplateExpires, loader, disabled, bgColor, cardTile,
  isPersonalizedInterview
}) {
  const snackbar = useSnackbar();
  const navigateWithClient = useNavigateWithClient();
  const [selectedBatch, setSelectedBatch] = useState(null);

  const [template, setTemplate] = useState();
  const [searchParams,] = useSearchParams();

  React.useEffect(() => {
    const batch = sessionStorage.getItem("selectedBatch")
    setSelectedBatch({_id : batch});
  }, [])

  React.useEffect(() => {
    const aid = searchParams.get('aid');
    const pid = searchParams.get('pid');

    if (!templates.length) return;

    if (pid) {
      const _template = templates.find(({ draft = {} }) => draft?._id === pid);

      if (isPersonalizedInterview && _template && !_template?._id) {
        navigateWithClient(`/personalized-interview/${_template?.draft._id}`);
      }
    }

    if (aid) {
      const _template = templates.find(({ _id }) => _id?.toString() === aid);

      if (_template?.maxAllowedAttempts >= 0 && _template?.attempts >= _template?.maxAllowedAttempts) {
        snackbar.error("Attempt limit reached for this assessment link")
        return;
      }

      if (_template) setTemplate(_template);
    }

  }, [searchParams, selectedBatch, templates.length]);

  return (
    <>
      {loader ?
        Array.from(new Array(3)).map((d, i) => {
          return <TemplateCardSkeleton key={i} />
        })
        :
        templates.map((template, index) => (
          <TemplateCard
            onExpire={onTemplateExpires}
            template={template}
            defaultTemplate={
              defaultTemplates.length === templates.length ?
                defaultTemplates[index] :
                null
            }
            onClick={(t) => setTemplate(t)}
            key={index}
            disabled={disabled}
            bgColor={bgColor}
            cardTile={cardTile}
            personalizedIntreview={isPersonalizedInterview}
          />
        ))
      }
      <StartAssessmentDialog
        onClose={() => setTemplate(undefined)}
        open={!!template}
        template={template}
        clientMock={isPersonalizedInterview ? true : false}
      />
    </>
  );
}
