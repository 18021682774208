import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import dayjs from "dayjs";

import { TourProviderContext, useServices, useSnackbar } from "contexts";
import { getDraftById, getTemplateById, getUserById, getUserRank, updateBatchUserMapping } from "services";
import RankCard from "components/RankCard";
import RecentPerformance from "./RecentPerformance";
import TourStartDialog from "dialogs/TourStartDialog";
import PathwayTodoList from "./PathwayTodoList";
import TodoList from "./TodoList";
import AnalyticsBrief from "../AnalyticsBrief";
import { Session, getAverageScore, getPercentageChange } from "utils";
import { DashboardTourSteps, TagLines } from "./constants";
import AppShell, { WhiteBox } from "components/AppShell";
import SelectBatch, { isPreviousDateExists } from "components/SelectBatch";
import ReplayTutorial from "components/ReplayTutorial";

const useStyles = makeStyles((theme) => ({
  welcome: {
    display: 'flex', justifyContent: 'space-between', gap: '10px',
  },
  scoreCards: {
    display: "flex",
    gap: theme.spacing(4),
    marginTop: theme.spacing(6),
  },
  tagImg: {
    marginRight: theme.spacing(2),
    alignSelf: 'start',
    display: 'flex',
    width: '32px',
  }
}));

function ScoreAverage({
  selectedBatch = '',
  batches = [],
  loading = false
}) {
  const [userAverage, setUserAverage] = useState(null);
  const [batchAverage, setBatchAverage] = useState(null);

  useEffect(() => {
    const batch = batches.find((v) => v._id === selectedBatch);
    if (batches.length > 0 && batch) {
      const user = batch.user;

      const currUserAverage = getAverageScore(
        user.communication, user.content
      );
      const prevUserAverage = getAverageScore(
        user.prevCommunication, user.prevContent
      );
      const userAvgDifference = getPercentageChange(currUserAverage, prevUserAverage);

      const currBatchAverage = getAverageScore(
        batch.communication, batch.content
      );
      const prevBatchAverage = getAverageScore(
        batch.prevCommunication, batch.prevContent
      );
      const batchAvgDifference = getPercentageChange(currBatchAverage, prevBatchAverage);

      setUserAverage({
        value: currUserAverage,
        percentage: (userAvgDifference < 0) ?
          userAvgDifference * (-1) :
          userAvgDifference,
        increment: userAvgDifference >= 0
      });
      setBatchAverage({
        value: currBatchAverage,
        percentage: (batchAvgDifference < 0) ?
          batchAvgDifference * (-1) :
          batchAvgDifference,
        increment: batchAvgDifference >= 0
      });
    }
  }, [batches, selectedBatch]);

  return (
    <>
      <AnalyticsBrief
        title="Your Average"
        data={userAverage?.value || 0}
        percentage={userAverage?.percentage || 0}
        increment={userAverage?.increment}
        subTitle="Your Cumulative Assessment Average Score"
        info={'Reflects changes in your overall average score after the last attempt'}
        showComparision={!Session.user.metadata?.isB2cUser}
        loading={loading}
      />

      <Box id='rank-and-average'>
        <AnalyticsBrief
          title={"Batch Average"}
          data={batchAverage?.value || 0}
          percentage={batchAverage?.percentage || 0}
          increment={batchAverage?.increment || false}
          subTitle="Average score representing your batch's performance"
          info={"Reflects changes in the overall average score of the batch after the last assessment"}
          showComparision={!Session.user.metadata?.isB2cUser}
          loading={loading}
        />
      </Box>
    </>
  );
}

function Home() {
  const service = useServices();
  const snackbar = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const { setSteps, play } = useContext(TourProviderContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const [tagLine, setTagLine] = useState("Let’s improve your communication every day to reach your goals");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [batches, setBatches] = useState([]);
  const [user, setUser] = useState({});
  const [rank, setRank] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const updateTagLine = React.useCallback((tagLine) => setTagLine(tagLine), []);

  const batchData = React.useMemo(() => {
    const selectedBatchData = batches?.find(batch => batch?._id === selectedBatch?._id);

    return selectedBatchData;
  }, [batches, selectedBatch]);

  React.useEffect(() => {
    (async () => {
      const selectedBatchData = batches.find(batch => batch._id === selectedBatch?._id);
      if (isPreviousDateExists(selectedBatchData?.notificationDate)) {
        const updatedDates = selectedBatchData.notificationDate.filter(date => dayjs(date).isAfter(dayjs()))

        await updateBatchUserMapping(
          selectedBatchData?.batchUserMappingId,
          {
            batch: selectedBatchData?._id,
            user: selectedBatchData.user._id,
            client: selectedBatchData.client,
            notificationDate: updatedDates
          }
        );

        const newBatches = batches.map((data) => {
          if (data._id === selectedBatchData._id) {
            return { ...data, hasNotification: false, notificationDate: updatedDates };
          } else {
            return data;
          }
        });
        setBatches(newBatches);
      }
    })();
  }, [selectedBatch]);

  React.useEffect(() => {
    if (batchData?.forPathway) return;
    (async () => {
      if (selectedBatch?._id) {
        try {
          setLoading(true);

          const userRankFilters = {
            batch: selectedBatch?._id,
          };

          const userRank = await service.callService(
            getUserRank, userRankFilters
          );

          setRank(userRank);
          updateTagLine(TagLines[(userRank?.tagLineNumber || 3)]);

        } catch (error) {
          console.log(error);
          snackbar.error("Uh Oh! Unable to get the UserRank");
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [selectedBatch]);

  useEffect(() => {
    (async () => {
      try {
        if (Session.userId) {
          setLoading(true);
          const user = await service.callService(getUserById, Session.userId);
          setUser(user);
        }
      } catch (error) {
        snackbar.error("Unable to load user details");
      } finally {
        setLoading(false);
      }
    })();
  }, [Session.userId]);

  useEffect(() => {
    if (!localStorage.getItem("v1.6-home-tour") && !searchParams.get('aid')) {
      localStorage.setItem("v1.6-home-tour", true.toString());
      setSteps(DashboardTourSteps);
      setTimeout(() => setOpenWelcomeDialog(true), 2000);
    }
  }, []);

  const handleBatchChange = (batch) => {
    setSelectedBatch(batch);
    sessionStorage.setItem("selectedBatch", batch);
  };

  React.useEffect(() => {
    const aid = searchParams.get('aid');
    const pid = searchParams.get('pid');
    if ((aid || pid) && batches.length) {
      (async () => {
        try {
          const template = aid ? await getTemplateById(aid) : await getDraftById(pid);

          if (!template) {
            throw new Error("Invalid assessment link");
          }

          const batch = batches.find(b => b._id.toString() === template?.batch)
          handleBatchChange(batch)

        } catch (error) {
          console.error(error);
          snackbar.error("Invalid assessment link");
        }
      })();
    }
  }, [searchParams, batches]);

  useEffect(() => {
    if (play) {
      const showDummyBatches = batches?.length === 0;

      if (showDummyBatches) {
        setBatches(DashboardTourSteps?.data?.batches);
      }

      return () => {
        if (showDummyBatches) setBatches([]);
      };
    }
  }, [play]);

  return (
    <AppShell headerLabel='Dashboard'>
      <Box id='rank-and-average-container'>
        <WhiteBox style={{ padding: theme.spacing(7, 10, 10, 10) }}>
          <Box className={classes.welcome}>
            <Box display='flex' flexDirection='column'>
              <Typography variant="h3-medium" color='primary.main' mb={2}>
                {`Welcome ${user?.name?.split(" ")?.[0] || ""},`}
              </Typography>
              <Box display='flex'>
                <img
                  src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/targetimg.svg"
                  alt="goals"
                  className={classes.tagImg}
                />
                <Typography variant="h5-medium" color="shades.clr-black-900">
                  {
                    batchData?.forPathway
                      ? 'Begin your journey! Unlock modules, unleash your potential!'
                      : tagLine
                  }
                </Typography>
              </Box>
            </Box>

            <Box>
              <SelectBatch
                batches={batches}
                setBatches={setBatches}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
                showNotification={true}
              />
            </Box>
          </Box>
          {
            batchData?.forPathway ? null :
              <Box className={classes.scoreCards}>
                <RankCard
                  rank={rank}
                  loading={loading}
                />
                <ScoreAverage
                  batches={batches}
                  selectedBatch={selectedBatch?._id}
                  loading={loading}
                />
              </Box>
          }
        </WhiteBox>
      </Box>

      {
        batchData?.forPathway ?
          <PathwayTodoList batch={batchData} />
          :
          <TodoList batch={selectedBatch?._id} removedFromBatch={batchData?.removed} />
      }

      <RecentPerformance batches={batches} selectedBatch={selectedBatch?._id} batchData={batchData} />

      {
        batchData?.forPathway ? null :
          <ReplayTutorial start={() => setOpenWelcomeDialog(true)} steps={DashboardTourSteps} />
      }

      <TourStartDialog
        open={openWelcomeDialog}
        onClose={setOpenWelcomeDialog}
        title="Welcome to the platform!"
        description="Let's take a quick tour of our platform and see how it can help you land your dream job!"
      />
    </AppShell>
  );
}

export default Home;
