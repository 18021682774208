import * as React from 'react';
import { Collapse, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { isArray } from 'lodash';
import Skeleton from '@mui/material/Skeleton';

import { useTheme } from '@mui/material/styles';

import Gauge from 'components/Gauge';
import { SUB_CATEGORIES } from 'utils/features';
import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';
import InfoIconWithTooltip from 'components/InfoIconWithTooltip';
import HorizontalGraph from 'components/HorizontalGraph';
import { getComparisonTagDetails } from 'utils';
import Chip from 'components/Chip';

const useStyles = makeStyles(theme => ({
  cardMain: {
    display: 'flex', flexDirection: 'column',
    minWidth: '400px', maxWidth: '400px',
  },
  performanceCard: {
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-400'],
    borderRadius: theme.spacing(1),
  },
  seeMore: {
    display: 'flex', justifyContent: 'flex-end',
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.neutral['clr-400']}`,
    backgroundColor: theme.palette.neutral['clr-50'],
    borderRadius: theme.spacing(1.5, 1.5, 0, 0)
  },
  seeMoreContent: {
    display: 'flex', alignItems: 'center', cursor: 'pointer',
  },
  percentageCol: {
    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
  },
  contentBox: {
    padding: theme.spacing(3),
    backgroundImage: 'url("https://assets.languify.in/images/circle-illustrate.svg")',
    backgroundRepeat: 'no-repeat', backgroundPosition: 'right bottom',
  },
  header: {
    display: 'flex', justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  expandedBox: {
    marginTop: '-1px',
    paddingTop: theme.spacing(2),
    border: '1px solid',
    borderWidth: '1px 1px 1px 1px',
    borderColor: theme.palette.neutral['clr-400'],
    borderRadius: theme.spacing(0, 0, 1, 1),
    "&::-webkit-scrollbar": { width: '6px' },
    height: '350px', overflow: 'auto',
    padding: theme.spacing(2, 4)
  },
  collapseContentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 0)
  },
  wordBox: {
    border: "1px solid",
    borderColor: theme.palette.neutral['clr-600'],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
    minWidth: 'fit-content'
  },
  highlightBox: {
    backgroundColor: theme.palette.neutral['clr-50'],
    margin: theme.spacing(0, -4),
    padding: theme.spacing(3, 4)
  },
  scrollIcon: {
    cursor: 'pointer'
  },
  suggestion: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(3),
  }
}));

const Legends = ({ data }) => {
  return (
    <Box display='flex' gap={3} mt={2}>
      {data.map(({ color, label, icon = null }, index) => (
        <Box display='flex' alignItems='center' key={index}>
          {icon
            ? icon
            : <Box width={9} height={9} backgroundColor={color} borderRadius='50%'></Box>
          }
          <Typography ml={1} variant='overline-bold' color='neutral.clr-600'>
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export const MeterParameterAnalytics = ({
  currentParameter, previousParaAverage
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { min, max, value } = React.useMemo(() => {
    return { ...currentParameter.data }
  }, [currentParameter]);

  return (
    <Box mt={1}>
      <Typography variant='body01-semiBold'>
        In this attempt your {currentParameter?.title} was &nbsp;
        <span style={{ color: currentParameter?.getColor(value) }}>
          {
            currentParameter?.valueParser(value)
          }
        </span>
      </Typography>
      <Box className={classes.collapseContentBox}>
        <Gauge
          low={min} high={max}
          value={value}
          value2={previousParaAverage.data.value}
          idealRange={currentParameter?.idealRange}
        />

        <Legends data={currentParameter?.legends} />
        <Legends
          data={[
            {
              label: 'This Attempt', color: theme.palette.primary.main,
              icon: <ArrowUpwardIcon style={{ fontSize: '16px', color: theme.palette.primary.main }} />
            },
            {
              label: 'Previous Attempt', color: theme.palette.secondary['clr-800'],
              icon: <ArrowUpwardIcon style={{ fontSize: '16px', color: theme.palette.secondary['clr-800'] }} />
            },
          ]}
        />
      </Box>
    </Box>
  )
}

const ArrayParameters = ({ title, data, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [scroll, setScroll] = React.useState();

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='body01-medium'>
          {title}: {data?.length || 0}
        </Typography>
        {
          data?.length > 5
            ? <Box display='flex' justifyContent='flex-end' gap={4}>
              <Box className={classes.scrollIcon} onClick={() => setScroll({ direction: "left" })}>
                <img
                  src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/leftArrow.svg"
                  alt="moveLeftIcon"
                />
              </Box>
              <Box className={classes.scrollIcon} onClick={() => setScroll({ direction: "right" })}>
                <img
                  src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/rightArrow.svg"
                  alt="moveLeftIcon"
                />
              </Box>
            </Box>
            : null
        }
      </Box>
      <HorizontalScrollNavigator
        childrensLength={(isArray(data) ? data : [])?.length} scroll={scroll}
        showNavigator={false} sx={{ gap: theme.spacing(2) }} scrollWidth={300}
      >
        {(isArray(data) ? data : [])?.map((item, index) => {
          const splitted = item.split(" ");
          const firstHalf = item.slice(0, -(splitted[splitted.length - 1]).length)
          const secondHalf = item.slice(-(splitted[splitted.length - 1]).length);

          return (
            <Box className={classes.wordBox}>
              <Typography variant='body02-semiBold' color='primary.clr-400'>
                <span>{firstHalf}</span>
                <span style={{ color: props?.highlight || theme.palette.danger.main }}>
                  {secondHalf}
                </span>
              </Typography>
            </Box>
          );
        })}
      </HorizontalScrollNavigator>
    </>
  )
}

export const LinearParameterAnalytics = ({
  title, idealRange, data, currentValue = 0, previousValue = 0, compareWith
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const additionalText = title === "Disfluencies" ? "breakdown" : "";
  const isFillerWords = title === 'Filler words' ? "filler words" : '';
  const isJargonWords = title === 'Jargon words' ? "jargon words" : '';
  const isRareWords = title === 'Rare words' ? "rare words" : '';

  return (
    <>
      <Box mt={1}>
        <Typography variant='body01-semiBold' color='success.main'>
          Ideal Range: &nbsp;
          {idealRange?.min}% to {idealRange?.max}%
        </Typography>
        <Box className={classes.collapseContentBox}>
          <HorizontalGraph
            range={idealRange}
            value1={currentValue}
            value2={previousValue}
          />
          <Legends
            data={
              [{ color: theme.palette.success['clr-100'], label: 'Ideal range' },
              { color: theme.palette.danger['clr-100'], label: 'Non-ideal range' }]
            }
          />
          <Legends
            data={
              [{ color: theme.palette.primary['clr-300'], label: 'This Attempt' },
              { 
                color: theme.palette.secondary['clr-800'], 
                label: compareWith==='latestAttempt' ? 'Latest Attempt' : 'Cumulative Average'
              }]
            }
          />
        </Box>
      </Box>
      <Box className={classes.highlightBox}>
        {additionalText && (
          <>
            <Typography variant='body01-medium'>
              Breakdowns Identified
            </Typography>
            <Box className={classes.wordBox} style={{ width: 'fit-content' }}>
              <Typography variant='body02-semiBold' color='primary.clr-400'>
                {data?.value} {additionalText}
              </Typography>
            </Box>
          </>
        )}

        {(isFillerWords) && <ArrayParameters data={data?.value} title={"Filler words Identified"}/>}
        {
          (isJargonWords) &&
          <ArrayParameters data={data?.value} title={"Jargon words Identified"} highlight='#237611'/>
        }
        {
          (isRareWords) &&
          <ArrayParameters data={data?.value} title={"Rarely used words Identified"} highlight='#237611'/>
        }
      </Box>
    </>
  )
}

export const GamificationCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.cardMain}>
      <Box className={classes.performanceCard}>
        <Box className={classes.seeMore}>
          <Skeleton variant="rectangular" width="100%" height='30px' />
        </Box>
        <Box className={classes.contentBox}>
          <Box className={classes.header}>
            <Skeleton variant="rectangular" width="100%" height='40px' />
          </Box>
          <Box className={classes.percentageCol}>
            <Skeleton variant="rectangular" width="100%" height='55px' />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const GamificationCard = ({
  previousParaAverage, currentParameter, compareWith, ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [expandCard, setExpandCard] = React.useState(false);

  const { currentValue, previousValue } = React.useMemo(() => {
    return (
      {
        currentValue: currentParameter?.valueGetter(currentParameter?.data),
        previousValue: previousParaAverage?.valueGetter(previousParaAverage?.data)
      }
    )
  }, [currentParameter, previousParaAverage]);

  const { tag, tagBgColor, tagColor, arrow = null } = React.useMemo(() => {
    let data = {};

    switch (currentParameter?.subCategory) {
      case SUB_CATEGORIES.ENERGY:
      case SUB_CATEGORIES.PACE:
        data = getComparisonTagDetails(currentParameter?.idealRange, previousValue, currentValue);
        break;

      case SUB_CATEGORIES.FILLER_WORDS:
      case SUB_CATEGORIES.DISFLUENCY:
        data = getComparisonTagDetails(currentParameter?.idealRange, previousValue, currentValue, true);
        break;

      case SUB_CATEGORIES.JARGON_WORDS:
      case SUB_CATEGORIES.RARE_USED_WORDS:
        data = getComparisonTagDetails(currentParameter?.idealRange, previousValue, currentValue, true, true);
        break;

      default:
        break;
    }

    return { ...data }
  }, [previousValue, currentValue]);

  return (
    <Box className={classes.cardMain}>
      <Box className={classes.performanceCard}
        style={expandCard ? { borderRadius: theme.spacing(1, 1, 0, 0) } : null}
      >
        <Box className={classes.seeMore}>
          <Box className={classes.seeMoreContent} onClick={(e) => setExpandCard(!expandCard)}>
            <Typography variant='body02-bold'>
              {expandCard ? 'Close' : 'See more'}
            </Typography>
            &nbsp;
            {
              expandCard
                ? <CloseIcon style={{ color: theme.palette.danger.main, fontSize: '16px' }} />
                : <KeyboardArrowDownIcon style={{ fontSize: '16px' }} />
            }
          </Box>
        </Box>
        <Box className={classes.contentBox}>
          <Box className={classes.header}>
            <Box display='flex' alignItems='center' gap={1}>
              <Typography variant='h6-medium' color='neutral.clr-700'>
                {currentParameter?.title}
              </Typography>
              <span>
                <InfoIconWithTooltip title={currentParameter?.info} sx={{ fontSize: '18px' }} />
              </span>
            </Box>
            <Chip
              content={tag}
              endIcon={arrow && arrow}
              color={tagColor}
              bgColor={tagBgColor}
              sx={{
                borderRadius: theme.spacing(2),
                padding: theme.spacing(1.5, 3),
                textTransform: 'capitalize'
              }}
            />
          </Box>
          <Box className={classes.percentageCol}>
            <Typography variant='h2-regular' color='primary.main'>
              {currentParameter?.valueParser(currentValue)}
            </Typography>
            <Typography variant='body01-medium' color='primary.clr-400'>
              (This Attempt)
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* expanded box */}
      <Collapse in={expandCard}>
        <Box className={classes.expandedBox}>
          <Typography variant='body01-bold'>
            Performance Graph
          </Typography>
          {
            currentParameter?.type === 'meter' ?
              <MeterParameterAnalytics
                previousParaAverage={previousParaAverage}
                currentParameter={currentParameter}
              />
              :
              <LinearParameterAnalytics
                currentParameter={currentParameter}
                currentValue={currentValue}
                previousValue={previousValue}
                title={currentParameter?.title}
                idealRange={currentParameter?.idealRange}
                data={currentParameter?.data}
                compareWith={compareWith}
              />
          }
          {
            !(currentValue >= currentParameter?.idealRange?.min && 
            currentValue <= currentParameter?.idealRange?.max) ?
            <Box className={classes.suggestion}>
              <Box mb={2}>
                <Typography variant='h6-semiBold' color='success.clr-500'>
                  Suggestion for You
                </Typography>
              </Box>
              <Typography variant='body02-bold'>
                {
                  currentParameter?.generateTip
                    ? currentParameter.generateTip(currentParameter?.data?.value)
                    : currentParameter?.tip
                }
              </Typography>
            </Box>
            : null
          }
        </Box>
      </Collapse>
    </Box>
  );
};

export default GamificationCard;