import * as React from 'react';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import makeStyles from "@mui/styles/makeStyles";
import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';
import useStore from './store';
import CustomButton from 'components/CustomButton';

import { Divider, Typography } from '@mui/material';
import { filterByRequiredParameter } from 'utils/features';
import { GamificationCategories } from './constants';
import { useServices, useSnackbar } from 'contexts';
import { Session } from 'utils';
import { getGamificationScores } from 'services';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SimpleSelect } from 'components/CustomSelectFields';
import GamificationCard, { GamificationCardSkeleton } from './GamificationCard';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
    padding: theme.spacing(5, 15)
  },
  footerSpan: {
    ...theme.typography['h5-medium']
  },
  contentSection: {
    padding: theme.spacing(5, 0),
  }
}));

const comparisonTypes = [
  { _id: 'latestAttempt', name: 'Latest Attempt' },
  { _id: 'cumulativeAverage', name: 'Cumulative Average' }
]

const Gamification = ({ disableRetake, currentAttemptNo, handlePracticeAgain, ...props }) => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();

  const template = useStore(state => state.template, shallow);
  const attemptId = useStore(state => state.attemptId, shallow);

  const [loading, setLoading] = React.useState(false);
  const [compareWith, setCompareWith] = React.useState(comparisonTypes[0]);
  const [parameterScoresAvg, setParameterScoresAvg] = React.useState();
  const [currAttemptPara, setCurrAttemptPara] = React.useState();
  const [gamificationParamters, setGamificationParamters] = React.useState();

  React.useEffect(() => {
    if (!attemptId?._id && !template._id) return;
    (async () => {
      try {
        setLoading(true);

        const filters = {
          userId: Session.userId,
          interviewAttempt: attemptId?._id,
          interviewTemplate: template._id,
        }

        const parameters = await service.callService(getGamificationScores, filters);

        const cumulativeAvg = filterByRequiredParameter(parameters?.cumulativeAvg, GamificationCategories);
        const lastAttemptAvg = filterByRequiredParameter(parameters?.lastAttemptAvg, GamificationCategories);
        const currentAttemptAvg = filterByRequiredParameter(parameters?.currentAttemptAvg, GamificationCategories);

        setGamificationParamters({ cumulativeAvg, lastAttemptAvg, currentAttemptAvg })
      } catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get the performance");
      } finally {
        setLoading(false);
      }
    })();
  }, [attemptId, template]);

  React.useEffect(() => {
    if (!gamificationParamters) return;

    setCurrAttemptPara(gamificationParamters?.currentAttemptAvg);
    setParameterScoresAvg(
      compareWith?._id === 'latestAttempt'
        ? gamificationParamters?.lastAttemptAvg
        : gamificationParamters?.cumulativeAvg
    );
  }, [gamificationParamters, currentAttemptNo, compareWith]);

  return (
    <WhiteBox>
      <WhiteBoxHeader
        heading='Progress Analysis'
        subheading='Here you will find detailed feedback over your parameters performance'
      >
        <SimpleSelect
          options={comparisonTypes}
          onSelect={data => setCompareWith(data)}
          value={compareWith}
          label='Compare Performance'
        />
      </WhiteBoxHeader>
      <Divider />

      {/* content */}
      <Box className={classes.contentSection}>
        <HorizontalScrollNavigator
          childrensLength={parameterScoresAvg?.length}
          scrollWidth={800}
        >
          {
            loading ?
              Array.from(new Array(5)).map((v, i) => {
                return <GamificationCardSkeleton key={i} />
              })
              :
              parameterScoresAvg?.map((para, index) => {
                return (
                  <GamificationCard
                    key={index}
                    previousParaAverage={para}
                    compareWith={compareWith?._id}
                    currentParameter={
                      currAttemptPara?.find(curr => curr?.subCategory === para?.subCategory)
                    }
                  />
                )
              })
          }
        </HorizontalScrollNavigator>
      </Box>
      <Divider/>

      {/* footer */}
      <Box className={classes.footer}>
        <Typography variant='h6-medium'>
          Have another conversation: &nbsp;
          <span className={classes.footerSpan}>Mastering success at MAANG💪</span>
        </Typography>
        <CustomButton
          variant='contained'
          disabled={disableRetake}
          onClick={handlePracticeAgain}
        >
          Take another Attempt
        </CustomButton>
      </Box>
    </WhiteBox>
  )
}

export default Gamification;