import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/styles';

const useStyles = makeStyles(theme=> ({
  root: { 
    boxShadow: 'none', 
    backgroundColor: theme.palette.shades['clr-white-900'], 
    display: 'flex',
    padding: theme.spacing(7,10,4,10), 
    justifyContent: 'space-between', 
    borderBottom: '1px solid #D1D2D6',
    height:'100%'
  },
  heading: { 
    display: 'flex', 
    flexDirection: 'column', 
    gap: theme.spacing(2) , 
    justifyContent:'center' ,
  },
}));

const DialogHeader = ({ title, subtitle, handleClose , sx={} }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.root}  style={{ ...sx }} >
      <Box className={classes.heading}>
        <Typography variant='h3-medium' color='primary.main'> {title} </Typography>
        <Typography variant='h5-medium' color='neutral.clr-800'> {subtitle} </Typography>
      </Box>
      <Button
        aria-label="close"
        onClick={handleClose}
        style={{ borderRadius: '50%' , height:'60px'}}
      >
        <CloseIcon style={{ fontSize: '30px' , color:theme.palette.shades['clr-black-900'] }}/>
      </Button>
    </Box>
  )
}

export default DialogHeader;